<template>
    <div class="reportPopup" :v-if="reportVisible">
        <div class="box innerBox" >
            <div class="popTitle popTitleArea">
                <p>보고서 다운로드</p>
                <div class="buttonArea">
                    <button class="btn solid small downloadBtn" @click="downloadReport($event)" v-ripple><i class="micon">file_download</i> 파일 다운로드</button>
                    <button class="x" @click="closeReport()"><i class="micon">close</i></button>
                </div>
            </div>
            
            <div class="reportWrapper">
                <div id="report" ref="printDoc" :key="pdfKey">
                    <ul class="wrap">
                        <!--1-->
                        <li class="page page1">
                            <div class="titW">
                                <p class="year"></p>
                                <p class="pTitle">Pine-DXS 보고서</p>
                            </div>
                            <ul class="patiInfo">
                                <li>
                                    <p class="tit">이름</p>
                                    <p class="txt">{{ patient.name }}</p>
                                </li>
                                <li>
                                    <p class="tit">생년월일</p>
                                    <p class="txt">{{ patient.birth }}</p>
                                </li>
                                <li>
                                    <p class="tit">성별</p>
                                    <p class="txt">{{ patient.sexStr }}</p>
                                </li>
                                <li>
                                    <p class="tit">환자번호</p>
                                    <p class="txt">{{ patient.patientSn }}</p>
                                </li>
                                <li>
                                    <p class="tit">촬영일</p>
                                    <p class="txt">{{ record.current.pictureDtStr }}</p>
                                </li>
                            </ul>
                            <div class="logoArea">
                                <img src="../../../assets/images/colorLogo.svg" alt="Pine-DXC" />
                            </div>
                        </li>
                        <!--2-->
                        <li class="page page2">
                            <!-- <PatientInfoCompo :patient="patient" :pictureDt="record.current.pictureDtStr" /> -->
                            <!-- <p class="printTit">결과 요약</p> -->
                            <!--결과요약-->
                            <ul class="box normal">
                                <!--척추측만증 분석 결과-->
                                <li>
                                    <p class="boxTit">AI 기반 Cobb’s angle 분석 결과</p>
                                    <SpineCompo :record="record" :allImageLoaded="true" :isFirstRecord="record.isFirstSpine" :isReport="true" />
                                    <div class="graphBox">
                                        <GraphCompo :xrayType="'spine'" :xrayNo="xrayNo" :width="'680'" :isReport="true" v-if="isParentLoaded"/>
                                    </div>
                                </li>
                                <!--//척추측만증 분석 결과-->
                                <!--뼈나이측정결과-->
                                <li>
                                    <p class="boxTit">AI 기반 {{READING_HANDS_NAME}} 분석 결과</p>
                                    <HandsCompo :record="record" :allImageLoaded="true" :isFirstRecord="record.isFirstHands" :isReport="true" />
                                    <div class="graphBox">
                                        <GraphCompo :xrayType="'hands'" :xrayNo="xrayNo" :width="'680'" :isReport="true" v-if="isParentLoaded"/>    
                                    </div>
                                </li>
                                <!--//뼈나이측정결과-->
                            </ul>
                            <!--//결과요약-->
                            
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import API_REPORT from '../../../API/record/report'
export default {
    components: {
        PatientInfoCompo: () => import("@/components/patientInfoCompo.vue"),
        GraphCompo: () => import("@/components/graphCompo.vue"),
        SpineCompo: () => import("@/components/spineReportCompo.vue"),
        HandsCompo: () => import("@/components/handsReportCompo.vue"),
    },
    props: {
        reportVisible: {
            default: false
        },
        onClick: {
            default: () => { }
        },
        xrayNo: {
            default: ''
        },
        isReport: {
            default: true
        }
    },
    data() {
        return {
            crtYear: '',
            today: '',
            isGettingItems: false,
            patient: {},
            record: {
                current: '',
                firstSpine: '',
                firstHands: '',
                prev: '',
                isFirstSpine: false,
                isFirstHands: false,
            },
            pdfKey: 0,
            isParentLoaded: false,
        };
    },
    watch: {
    },
    created() { },
    async mounted() {
        await this.initData(this.xrayNo)
        this.getDate()
        this.isParentLoaded = true;
        //this.downloadReport()
    },
    destroyed() { },
    methods: {
        getDate() {
            let today = new Date();
            let year = today.getFullYear();
            let month = String(today.getMonth() + 1).padStart(2, '0');
            let day = String(today.getDate()).padStart(2, '0');
            this.crtYear = year;
            this.today = year + month + day;
        },
        async initData(xrayNo) {
            if (this.isGettingItems) {
                return;
            }
            this.isGettingItems = true;
            let reportRes = await API_REPORT.request(xrayNo);
            if (reportRes.isSuccess) {
                this.patient = reportRes.patient;
                this.record.current = reportRes.currentRecord;
                this.record.isFirstSpine = reportRes.isFirstSpine;
                this.record.firstSpine = reportRes.firstSpineRecord;
                this.record.isFirstHands = reportRes.isFirstHands;
                this.record.firstHands = reportRes.firstHandsRecord;
                this.record.prev = reportRes.prevRecord;

                this.roundAngles(this.record.current);
                this.roundAngles(this.record.firstSpine);
                this.roundAngles(this.record.prev);

            } else {
                this.showPopup(reportRes.errorMsg, reportRes.status);
            }
        },
        resetData() {
            this.record = {
                isFirstSpine: true,
                firstSpine: '',
                isFirstHands: true,
                firstHands: '',
                current: '',
                prev: ''
            }
            this.values = {
                patientNo: '',
                xrayNo: ''
            }
            this.patient = {}
        },
        closeReport() {
            this.resetData();
            this.isGettingItems = false;
            this.$emit('closeReport');
        },
        downloadReport(event) {
            if(event) event.preventDefault();
            if (this.isGettingItems) {
                this.$htmlToPdf(this.$refs.printDoc, `[Pine-DXS] ${this.patient.name} 리포트_${this.today}`);
                this.pdfKey += 1
            }
        },
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";

.reportPopup {
    position:fixed; left:0; top:0; z-index:30; width:100vw; height:100vh; background:rgba(0,0,0,0.8);
    .innerBox{
        position:relative; left:50%; top:50%; transform:translate(-50%, -50%); background:#fff; max-width:800px; border-radius:16px; box-shadow:0 0 15px rgba(0,0,0,0.1); overflow: hidden; max-height: 95vh;
        .popTitle{
            padding:24px;font-size:2.0rem; font-weight:600; border-bottom:1px solid #dbdbdb;
            &.popTitleArea{
                padding: 16px 24px !important;
                display:flex;
                justify-content: space-between;
                align-items: center;
            }
            .buttonArea{
                min-width: 200px;
                display:flex;
                justify-content: space-between;
                align-items: center;
                .downloadBtn{
                    i{font-size:1.5rem;}
                }
                .x{position:absolute; right:24px; top:20px; }
            }
        }
    }
}

.reportWrapper{
    max-height:800px;
    overflow: auto;
    #report{
        overflow:hidden;
        li{
            &.page{
                clear: both;
                page-break-after: always;
            }
        }
    }
}

.refText{display:inline-block; font-size: 9px; margin-top: 10px; color: #666; line-height: 1.5rem; word-break: keep-all;}
</style>

